import { DOWNLOAD_INVOICE } from '../actions/invoice';
import { DOWNLOAD_CHARGER_LOG_DATA, DOWNLOAD_TABULAR_METERVALUE } from '../actions/activityLog';
import { DOWNLOAD_CUSTOMER_BOOKING, DOWNLOAD_CUSTOMER_TRANSACTION, DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION } from '../actions/customer';
import { DOWNLOAD_RFID_REQUEST } from '../actions/rfidRequest';
import {
  DOWNLOAD_STATION_CHARGING_SUMMARY,
  DOWNLOAD_BOOKING_HISTORY,
  DOWNLOAD_USER_CHARGING_HISTORY,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_STATION_UNIT_CONSUMPTION,
  DOWNLOAD_STATION_DOWNTIME_REPORT,
  DOWNLOAD_CHARGING_HISTORY,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY,
  DOWNLOAD_AC_METER_READING_REPORT,
  DOWNLOAD_APP_USER_DETAIL_REPORT,
  DOWNLOAD_JOB_VOUCHER,
  DOWNLOAD_INVOICE_REPORT,
  DOWNLOAD_BOOKING_SUMMARY_REPORT,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST,
  DOWNLOAD_PAYMENT_STATUS_REPORT,
  DOWNLOAD_CAPACITY_DATA,
  DOWNLOAD_VEHICLE_CHARGING_SESSION,
  DOWNLOAD_FAULTY_BOOKING_REPORT,
  DOWNLOAD_CHARGING_SESSION_REPORT,
  DOWNLOAD_ALL_DATA_REPORT,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT,
  DOWNLOAD_OCPI_BOOKING,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY,
  DOWNLOAD_COUPON_USAGE_REPORT,
  DOWNLOAD_COUPON_PERFORMANCE_REPORT,
} from '../actions/dataReport';
import { CHARGER_STATUS_DOWNLOAD } from 'actions/chargerStatus';
import { DOWNLOAD_EXCEL_VEHICLE } from 'actions/vehicle';
import { DOWNLOAD_RATING_REVIEW, DOWNLOAD_RATING_REVIEW_SUMMARY } from 'actions/rating';

const initialState = {
  isLoading: false,
};

const downloadingLoaderReducer = (state = initialState, { type }) => {
  switch (type) {
    case DOWNLOAD_ALL_DATA_REPORT.REQUEST:
    case DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT.REQUEST:
    case DOWNLOAD_OCPI_BOOKING.REQUEST:
    case DOWNLOAD_INVOICE.REQUEST:
    case DOWNLOAD_CHARGER_LOG_DATA.REQUEST:
    case DOWNLOAD_CUSTOMER_BOOKING.REQUEST:
    case DOWNLOAD_RFID_REQUEST.REQUEST:
    case DOWNLOAD_BOOKING_HISTORY.REQUEST:
    case DOWNLOAD_USER_CHARGING_HISTORY.REQUEST:
    case DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST:
    case DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.REQUEST:
    case DOWNLOAD_WALLET_TRANSACTION_REPORT.REQUEST:
    case DOWNLOAD_STATION_DOWNTIME_REPORT.REQUEST:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION.REQUEST:
    case DOWNLOAD_CHARGING_HISTORY.REQUEST:
    case DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST:
    case DOWNLOAD_AC_METER_READING_REPORT.REQUEST:
    case DOWNLOAD_APP_USER_DETAIL_REPORT.REQUEST:
    case DOWNLOAD_JOB_VOUCHER.REQUEST:
    case DOWNLOAD_INVOICE_REPORT.REQUEST:
    case DOWNLOAD_BOOKING_SUMMARY_REPORT.REQUEST:
    case DOWNLOAD_MOST_VEHICLE_CHARGED_LIST.REQUEST:
    case DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST:
    case CHARGER_STATUS_DOWNLOAD.REQUEST:
    case DOWNLOAD_TABULAR_METERVALUE.REQUEST:
    case DOWNLOAD_PAYMENT_STATUS_REPORT.REQUEST:
    case DOWNLOAD_EXCEL_VEHICLE.REQUEST:
    case DOWNLOAD_CAPACITY_DATA.REQUEST:
    case DOWNLOAD_CUSTOMER_TRANSACTION.REQUEST:
    case DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION.REQUEST:
    case DOWNLOAD_VEHICLE_CHARGING_SESSION.REQUEST:
    case DOWNLOAD_FAULTY_BOOKING_REPORT.REQUEST:
    case DOWNLOAD_RATING_REVIEW.REQUEST:
    case DOWNLOAD_RATING_REVIEW_SUMMARY.REQUEST:
    case DOWNLOAD_CHARGING_SESSION_REPORT.REQUEST:
    case DOWNLOAD_PLANT_LOAD_FACTOR_REPORT.REQUEST:
    case DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST:
    case DOWNLOAD_PARTNER_CHARGING_SUMMARY.REQUEST:
    case DOWNLOAD_COUPON_USAGE_REPORT.REQUEST:
    case DOWNLOAD_COUPON_PERFORMANCE_REPORT.REQUEST: {
      return { ...state, isLoading: true };
    }

    case DOWNLOAD_ALL_DATA_REPORT.SUCCESS:
    case DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT.SUCCESS:
    case DOWNLOAD_OCPI_BOOKING.SUCCESS:
    case DOWNLOAD_INVOICE.SUCCESS:
    case DOWNLOAD_CHARGER_LOG_DATA.SUCCESS:
    case DOWNLOAD_CUSTOMER_BOOKING.SUCCESS:
    case DOWNLOAD_RFID_REQUEST.SUCCESS:
    case DOWNLOAD_BOOKING_HISTORY.SUCCESS:
    case DOWNLOAD_USER_CHARGING_HISTORY.SUCCESS:
    case DOWNLOAD_B2C_STATION_CHARGING_HISTORY.SUCCESS:
    case DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.SUCCESS:
    case DOWNLOAD_WALLET_TRANSACTION_REPORT.SUCCESS:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION.SUCCESS:
    case DOWNLOAD_STATION_DOWNTIME_REPORT.SUCCESS:
    case DOWNLOAD_CHARGING_HISTORY.SUCCESS:
    case DOWNLOAD_B2B_STATION_CHARGING_HISTORY.SUCCESS:
    case DOWNLOAD_AC_METER_READING_REPORT.SUCCESS:
    case DOWNLOAD_APP_USER_DETAIL_REPORT.SUCCESS:
    case DOWNLOAD_JOB_VOUCHER.SUCCESS:
    case DOWNLOAD_INVOICE_REPORT.SUCCESS:
    case DOWNLOAD_BOOKING_SUMMARY_REPORT.SUCCESS:
    case DOWNLOAD_MOST_VEHICLE_CHARGED_LIST.SUCCESS:
    case DOWNLOAD_STATION_CHARGING_SUMMARY.SUCCESS:
    case DOWNLOAD_TABULAR_METERVALUE.SUCCESS:
    case CHARGER_STATUS_DOWNLOAD.SUCCESS:
    case DOWNLOAD_PAYMENT_STATUS_REPORT.SUCCESS:
    case DOWNLOAD_EXCEL_VEHICLE.SUCCESS:
    case DOWNLOAD_CAPACITY_DATA.SUCCESS:
    case DOWNLOAD_CUSTOMER_TRANSACTION.SUCCESS:
    case DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION.SUCCESS:
    case DOWNLOAD_VEHICLE_CHARGING_SESSION.SUCCESS:
    case DOWNLOAD_FAULTY_BOOKING_REPORT.SUCCESS:
    case DOWNLOAD_RATING_REVIEW.SUCCESS:
    case DOWNLOAD_RATING_REVIEW_SUMMARY.SUCCESS:
    case DOWNLOAD_CHARGING_SESSION_REPORT.SUCCESS:
    case DOWNLOAD_PLANT_LOAD_FACTOR_REPORT.SUCCESS:
    case DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.SUCCESS:
    case DOWNLOAD_PARTNER_CHARGING_SUMMARY.SUCCESS:
    case DOWNLOAD_COUPON_USAGE_REPORT.SUCCESS:
    case DOWNLOAD_COUPON_PERFORMANCE_REPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case DOWNLOAD_ALL_DATA_REPORT.FAIL:
    case DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT.FAIL:
    case DOWNLOAD_OCPI_BOOKING.FAIL:
    case DOWNLOAD_INVOICE.FAIL:
    case DOWNLOAD_CHARGER_LOG_DATA.FAIL:
    case DOWNLOAD_CUSTOMER_BOOKING.FAIL:
    case DOWNLOAD_RFID_REQUEST.FAIL:
    case DOWNLOAD_BOOKING_HISTORY.FAIL:
    case DOWNLOAD_USER_CHARGING_HISTORY.FAIL:
    case DOWNLOAD_B2C_STATION_CHARGING_HISTORY.FAIL:
    case DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.FAIL:
    case DOWNLOAD_WALLET_TRANSACTION_REPORT.FAIL:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION.FAIL:
    case DOWNLOAD_STATION_DOWNTIME_REPORT.FAIL:
    case DOWNLOAD_CHARGING_HISTORY.FAIL:
    case DOWNLOAD_B2B_STATION_CHARGING_HISTORY.FAIL:
    case DOWNLOAD_AC_METER_READING_REPORT.FAIL:
    case DOWNLOAD_APP_USER_DETAIL_REPORT.FAIL:
    case DOWNLOAD_JOB_VOUCHER.FAIL:
    case DOWNLOAD_INVOICE_REPORT.FAIL:
    case DOWNLOAD_BOOKING_SUMMARY_REPORT.FAIL:
    case DOWNLOAD_MOST_VEHICLE_CHARGED_LIST.FAIL:
    case DOWNLOAD_STATION_CHARGING_SUMMARY.FAIL:
    case DOWNLOAD_TABULAR_METERVALUE.FAIL:
    case CHARGER_STATUS_DOWNLOAD.FAIL:
    case DOWNLOAD_PAYMENT_STATUS_REPORT.FAIL:
    case DOWNLOAD_EXCEL_VEHICLE.FAIL:
    case DOWNLOAD_CAPACITY_DATA.ERROR:
    case DOWNLOAD_CUSTOMER_TRANSACTION.FAIL:
    case DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION.FAIL:
    case DOWNLOAD_VEHICLE_CHARGING_SESSION.FAIL:
    case DOWNLOAD_FAULTY_BOOKING_REPORT.FAIL:
    case DOWNLOAD_RATING_REVIEW.FAIL:
    case DOWNLOAD_RATING_REVIEW_SUMMARY.FAIL:
    case DOWNLOAD_CHARGING_SESSION_REPORT.FAIL:
    case DOWNLOAD_PLANT_LOAD_FACTOR_REPORT.FAIL:
    case DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.FAIL:
    case DOWNLOAD_PARTNER_CHARGING_SUMMARY.FAIL:
    case DOWNLOAD_COUPON_USAGE_REPORT.FAIL:
    case DOWNLOAD_COUPON_PERFORMANCE_REPORT.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default downloadingLoaderReducer;
