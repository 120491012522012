import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { FETCH_COUPONS } from 'actions/coupon';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { DELETE_COUPON } from 'actions/coupon';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE, GET_COUPONS, MANAGE_COUPONS } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FiEdit2, FiBarChart2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import { GiSpeedometer } from 'react-icons/gi';

const CouponList = () => {
  const navigate = useNavigate();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  // const [allcouponsData, setAllcouponsData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [couponId, setcouponId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const dispatch = useDispatch();
  const allcoupons = useSelector((state) => state.coupon.coupons);
  const isLoader = useSelector((state) => state.coupon.isLoading);
  const page = useSelector((state) => state.coupon.page);
  const totalData = useSelector((state) => state.coupon.total);
  const limit = useSelector((state) => state.coupon.limit);
  const totalPages = useSelector((state) => state.coupon.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getCoupons = useCallback(
    (data) => {
      if (searchText) {
        const couponData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_COUPONS.REQUEST, payload: couponData });
      } else {
        dispatch({ type: FETCH_COUPONS.REQUEST, payload: data });
      }
    },
    [searchText]
  );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setcouponId('');
  };

  const deletecoupon = useCallback(
    () =>
      dispatch({
        type: DELETE_COUPON.REQUEST,
        payload: couponId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getCoupons({ page: 1 });
          }
        },
      }),
    [couponId]
  );

  const getcouponPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_COUPONS),
      (data) => data.name
    );

  const managecouponPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_COUPONS),
      (data) => data.name
    );

  const getAllUserGroup = useCallback((data = {}) => {
    dispatch({ type: FETCH_USER_GROUP.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getCoupons();
    getAllUserGroup();
  }, []);

  const getUserNamesByIds = useCallback(
    (userGroupIds) => {
      if (typeof userGroupIds === 'string') {
        userGroupIds = userGroupIds.split(',').map((id) => id.trim());
      } else if (!Array.isArray(userGroupIds)) {
        userGroupIds = [userGroupIds];
      }

      return userGroupIds
        .map((id) => {
          const group = allUserGroup.find((group) => group.id === id);
          return group ? group.name : 'Unknown Group';
        })
        .join(', ');
    },
    [allUserGroup]
  );

  const handleSearch = () => {
    getCoupons({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        -getCoupons(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCoupons(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCoupons(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleUsageReportClick = (couponId, startDate, endDate, code) => {
    const state = {
      couponId,
      startDate: startDate,
      endDate: endDate,
      code: code,
    };

    navigate('/couponUsageReport', { state }); // Ensure state is passed correctly
  };

  const handlePerformaceReportClick = (couponId, startDate, endDate) => {
    const state = {
      couponId,
      startDate: startDate,
      endDate: endDate,
    };

    navigate('/couponPerformanceReport', { state }); // Ensure state is passed correctly
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.couponManagement')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="coupon-management-page_main">
              <div className="coupon-list-main-block">
                <Card>
                  <div className="coupon-list-block">
                    <div className="coupon-search-box">
                      <Row>
                        <Col xl={3}>
                          <div className="coupon-title">{t('header.coupons')}</div>
                        </Col>
                        <Col xl={!_.isEmpty(managecouponPermissions) ? 6 : 12}>
                          <SearchBox
                            preIcon={<BsSearch />}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch();
                              }
                            }}
                            placeholder=""
                          />
                        </Col>
                        {(!_.isEmpty(managecouponPermissions) || (!_.isEmpty(getcouponPermissions) && !_.isEmpty(managecouponPermissions))) && (
                          <Col xl={3}>
                            <Button className="add-coupon-btn" onClick={() => navigateTo('/addCoupon')}>
                              {t('button.coupons')}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className="coupon-list-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponForm.srNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponForm.couponName')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponForm.couponNature')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponForm.couponCode')}</span>
                                  <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponForm.validity')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponForm.noOfGroups')} </span>
                                </div>
                              </th>
                              {(!_.isEmpty(managecouponPermissions) || (!_.isEmpty(getcouponPermissions) && !_.isEmpty(managecouponPermissions))) && (
                                <th>
                                  <div>
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('couponForm.action')}</span>
                                  </div>
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <div className="loader--block">
                                    <Skeleton height={15} width={100} />
                                  </div>
                                </td>
                              </tr>
                            ) : _.isEmpty(allcoupons) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('couponForm.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              _.map(allcoupons, (coupons, index) => {
                                const serial_num = limit * (page - 1) + index;
                                const formatDate = (date) => moment(date).format('DD-MM-YYYY hh:mm A');

                                const startDate = _.get(coupons, 'start_date_time', '');
                                const endDate = _.get(coupons, 'end_date_time', '');
                                const status = _.get(coupons, 'status', '');

                                const expiryDateTime = moment(endDate);
                                const isExpired = moment().isAfter(expiryDateTime);

                                const isDisabled = status === 'disable' || isExpired;

                                const isInactive = isExpired || isDisabled;

                                const userGroup = _.get(coupons, 'user_group', '');
                                let userGroupCount = 0;
                                let userGroupIds = [];

                                if (typeof userGroup === 'string') {
                                  userGroupIds = userGroup.split(',').filter((group) => group.trim() !== '');
                                  userGroupCount = userGroupIds.length;
                                } else if (Array.isArray(userGroup)) {
                                  userGroupIds = userGroup;
                                  userGroupCount = userGroup.length;
                                } else if (userGroup && typeof userGroup === 'object') {
                                  userGroupIds = Object.keys(userGroup);
                                  userGroupCount = userGroupIds.length;
                                } else if (userGroup) {
                                  userGroupIds = [userGroup];
                                  userGroupCount = 1;
                                }

                                const userGroupNames = getUserNamesByIds(userGroupIds);
                                const coupon_id = _.get(coupons, 'id', _.get(coupons, '_id', ''));

                                return (
                                  <tr key={`coupons-data-item-${serial_num}`}>
                                    <td
                                      style={{
                                        opacity: isDisabled ? 0.5 : 1,
                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}
                                    </td>
                                    <td
                                      className="couponLabel"
                                      style={{
                                        opacity: isDisabled ? 0.5 : 1,
                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(coupons, 'name', '-')}
                                    </td>
                                    <td
                                      className="natureLabel"
                                      style={{
                                        opacity: isDisabled ? 0.5 : 1,
                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(coupons, 'nature', '-')}
                                    </td>
                                    <td
                                      style={{
                                        opacity: isDisabled ? 0.5 : 1,
                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(coupons, 'code', '-')}
                                    </td>
                                    <td
                                      style={{
                                        opacity: isDisabled ? 0.5 : 1,
                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={100} /> : formatDate(startDate)} to {formatDate(endDate)}
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : isInactive ? (
                                        <span
                                          style={{
                                            color: isExpired ? 'red' : 'orange',
                                            fontWeight: 'bold',
                                          }}
                                          className="setLabel"
                                        >
                                          {isExpired ? 'Expired' : 'Disabled'}
                                        </span>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        opacity: isDisabled ? 0.5 : 1,
                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                      }}
                                    >
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>{userGroupNames}</Tooltip>}>
                                          <span>
                                            {userGroupCount} {userGroupCount === 1 || userGroupCount === 0 ? 'Group' : 'Groups'}
                                          </span>
                                        </OverlayTrigger>
                                      )}
                                    </td>

                                    {(!_.isEmpty(managecouponPermissions) ||
                                      (!_.isEmpty(getcouponPermissions) && !_.isEmpty(managecouponPermissions))) && (
                                      <td>
                                        <div key={`action-${index}`} className="coupon-list">
                                          <span className="list-icon">
                                            {isLoader ? (
                                              <Skeleton height={15} width={100} />
                                            ) : (
                                              <ul>
                                                <li
                                                  className="item"
                                                  onClick={() => !isDisabled && navigateTo(`/editcoupon/${coupon_id}`)}
                                                  style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                                >
                                                  <FiEdit2 title="Edit" size={22} color={isDisabled ? '#999' : '#3c7cdd'} />
                                                </li>
                                                {!_.isEmpty(managecouponPermissions) ||
                                                (!_.isEmpty(getcouponPermissions) && !_.isEmpty(managecouponPermissions)) ? (
                                                  <li
                                                    className="item"
                                                    onClick={() => {
                                                      if (!isDisabled) {
                                                        setShowDeleteModal(true);
                                                        setcouponId(coupon_id);
                                                      }
                                                    }}
                                                    style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                                  >
                                                    <RiDeleteBinLine title="Delete" size={22} color={isDisabled ? '#999' : '#be210b'} />
                                                  </li>
                                                ) : (
                                                  <li className="item">
                                                    <RiDeleteBinLine title="Delete" size={22} color="#be210b" />
                                                  </li>
                                                )}
                                                <li
                                                  className="item"
                                                  onClick={() =>
                                                    handleUsageReportClick(coupon_id, coupons.start_date_time, coupons.end_date_time, coupons.code)
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <FiBarChart2 title="Usage Report" size={22} color="#3c7cdd" />
                                                </li>
                                                <li
                                                  className="item"
                                                  onClick={() =>
                                                    handlePerformaceReportClick(coupon_id, coupons.start_date_time, coupons.end_date_time)
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <GiSpeedometer title="Performance Report" size={22} color="#f5604c" />
                                                </li>
                                              </ul>
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allcoupons) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                    {showDeleteModal && (
                      <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal title="coupons" onClose={handleCloseDeleteModel} onRemove={deletecoupon} />
                      </Modal>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default CouponList;
