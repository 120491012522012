import { countryToTimezoneMap } from './countryTimezone';

export const getTimezoneFromCountryCode = (countryCode) => {
  const cleanedCode = String(countryCode)
  .replace(/[^\d]/g, '')
  .trim();
    
  if (countryToTimezoneMap[cleanedCode]) {
    return countryToTimezoneMap[cleanedCode];
  }
  return countryToTimezoneMap.default;
};

