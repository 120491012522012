import React, { useCallback, useState, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CREATE_VOUCHER, GET_VOUCHER, UPDATE_VOUCHER } from 'actions/voucher';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import { FETCH_CUSTOMER } from 'actions/customer';
import { useSelector, useDispatch } from 'react-redux';
// import { GET_CHARGER_STATION } from 'actions/chargingStation';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import Button from 'components/inputs/Button';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const VoucherForm = () => {
  const dispatch = useDispatch();
  const { navigateTo } = useContext(NavContext);
  const { voucherId } = useParams();
  const { t } = useTranslation();
  const voucherDetail = useSelector((state) => state.voucher.voucherDetail);
  const allCustomer = useSelector((state) => state.customer.customers);
  const userPage = useSelector((state) => state.customer.page);
  const userTotalPage = useSelector((state) => state.customer.totalPages);
  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const userGroupPage = useSelector((state) => state.userGroup.page);
  const userGroupTotalPage = useSelector((state) => state.userGroup.totalPages);
  const [isEditable, setIsEditable] = useState(true);

  const initialValues =
    voucherId && !_.isEmpty(voucherDetail)
      ? {
          ...voucherDetail,
          redemption_type: voucherDetail.redemption_type || 'direct_discount',
          selectedType: voucherDetail.user ? 'user' : 'user_group', // Set initial selected type
          user: voucherDetail.user || [],
          user_group: voucherDetail.user_group || [],
        }
      : {
          name: '',
          redemption_type: 'direct_discount',
          discount_amount: '',
          user: [],
          user_group: [],
          comment: '',
          code: '',
          selectedType: 'user',
        };

  const addCoupon = useCallback((data) => {
    dispatch({
      type: CREATE_VOUCHER.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/vouchers');
          }, 1000);
        }
      },
    });
  }, []);

  const updateCoupon = useCallback((data) => {
    dispatch({
      type: UPDATE_VOUCHER.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/vouchers');
          }, 500);
        }
      },
    });
  }, []);

  const CouponFormSchema = Yup.object({
    name: Yup.string().required(t('voucherForm.name')).strict(true).max(100, t('voucherForm.maxlimit')),
    redemption_type: Yup.string().default('direct_discount').required(t('voucherForm.redemption')),
    discount_amount: Yup.number().required(t('voucherForm.voucherAmounts')).moreThan(0, 'Voucher amount must be greater than zero'), // Updated rule
    code: Yup.string().required(t('voucherForm.code')), // Removed space validation
    selectedType: Yup.string().required('Please select either User or User Group'),
    user: Yup.array().when('selectedType', {
      is: 'user',
      then: Yup.array().min(1, 'Please select at least one user'),
      otherwise: Yup.array().of(Yup.mixed().notRequired()),
    }),
    user_group: Yup.array().when('selectedType', {
      is: 'user_group',
      then: Yup.array().min(1, 'Please select at least one user group'),
      otherwise: Yup.array().of(Yup.mixed().notRequired()),
    }),
  });

  const getAllUsers = useCallback((data) => {
    const userData = {
      ...data,
      limit: 99999,
    };
    dispatch({ type: FETCH_CUSTOMER.REQUEST, payload: userData });
  }, []);

  const getAllUserGroup = useCallback((data = {}) => {
    dispatch({ type: FETCH_USER_GROUP.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllUsers();
    getAllUserGroup();
  }, []);

  useEffect(() => {
    if (voucherId && !_.isEmpty(voucherDetail)) {
      setIsEditable(voucherDetail.status !== 'disable');
    }
  }, [voucherId, voucherDetail]);

  const getVoucherById = useCallback((id) => {
    dispatch({ type: GET_VOUCHER.REQUEST, payload: id });
  }, []);

  useEffect(() => {
    if (voucherId) {
      getVoucherById(voucherId);
    }
  }, [voucherId]);

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${voucherId ? 'Edit' : 'Add'} ${t('header.vouchers')}`} backTo="/vouchers" />
        <div className="page-content-wrapper scrollable">
          <div className="add-voucher-page-main">
            <Card className="voucher-form-card">
              <div className="voucher-form__block">
                <Formik
                  enableReinitialize={!!voucherId}
                  initialValues={initialValues}
                  validationSchema={CouponFormSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (!isEditable) {
                      toast.error('This voucher is disabled and cannot be edited.');
                      setSubmitting(false);
                      return;
                    }

                    let submitData = {
                      ...values,
                    };

                    // Remove the field that wasn't selected
                    if (values.selectedType === 'user') {
                      delete submitData.user_group;
                    } else if (values.selectedType === 'user_group') {
                      delete submitData.user;
                    }

                    // Remove selectedType as it's not needed in the backend
                    delete submitData.selectedType;

                    if (voucherId) {
                      const updateData = _.omit(submitData, ['tenant', 'createdAt', 'updatedAt']);
                      updateCoupon(updateData);
                    } else {
                      addCoupon(submitData);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, handleSubmit, handleChange, setFieldValue, errors, touched, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6}>
                          <TextInput
                            isRequired
                            label={t('voucherForm.voucherName')}
                            placeholder={t('voucherForm.enterVocherName')}
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? errors.name : null}
                          />
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <Select
                            label={t('voucherForm.redemptionType')}
                            placeholder={t('voucherForm.selectRedemptionType')}
                            options={[
                              { label: 'Direct Discount', value: 'direct_discount' },
                              { label: 'Cashback', value: 'cashback' },
                              { label: 'ChargeCoins', value: 'charge_coins' },
                            ]}
                            name="redemption_type"
                            value={values.redemption_type}
                            onChange={(val) => setFieldValue('redemption_type', val)}
                            error={errors.redemption_type && touched.redemption_type ? errors.redemption_type : null}
                          />
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <div className="voucher-amount">
                            <TextInput
                              type="number"
                              label={t('voucherForm.voucherAmount')}
                              placeholder={t('voucherForm.enterVoucherAmount')}
                              name="discount_amount"
                              value={values.discount_amount}
                              onChange={handleChange}
                              error={errors.discount_amount && touched.discount_amount ? errors.discount_amount : null}
                              isRequired
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12}>
                          <Select
                            label="Select User or User Group"
                            options={[
                              { label: 'User', value: 'user' },
                              { label: 'User Group', value: 'user_group' },
                            ]}
                            name="selectedType"
                            value={values.selectedType}
                            onChange={(val) => {
                              setFieldValue('selectedType', val);
                              setFieldValue('user', '');
                              setFieldValue('user_group', []);
                            }}
                            error={errors.selectedType && touched.selectedType ? errors.selectedType : null}
                          />
                        </Col>
                        {values.selectedType === 'user' && (
                          <Col lg={12} md={12}>
                            <Select
                              label={t('voucherForm.user')}
                              options={_.map(allCustomer, (item) => ({
                                label: `${_.get(item, 'name', 'Guest User')} +${_.get(item, 'country_code')} ${_.get(item, 'phone')}`,
                                value: item.id,
                              }))}
                              className="filter-select-box"
                              placeholder={t('voucherForm.selectUser')}
                              name="user"
                              value={values.user}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={getAllUsers}
                              page={userPage}
                              totalPage={userTotalPage}
                              isMulti
                              onChange={(val) => setFieldValue('user', val)}
                              error={errors.user && touched.user ? errors.user : null}
                            />
                          </Col>
                        )}
                        {values.selectedType === 'user_group' && (
                          <Col lg={12} md={12}>
                            <Select
                              label={t('voucherForm.userGroup')}
                              options={_.map(allUserGroup, (item) => ({ label: item.name, value: item.id }))}
                              className="filter-select-box"
                              placeholder={t('voucherForm.selectUserGroup')}
                              name="user_group"
                              value={values.user_group}
                              onMenuScrollDown={true}
                              page={userGroupPage}
                              totalPage={userGroupTotalPage}
                              getDataOnScrollDown={getAllUserGroup}
                              isMulti
                              onChange={(val) => setFieldValue('user_group', val)}
                              error={errors.user_group && touched.user_group ? errors.user_group : null}
                            />
                          </Col>
                        )}
                        <Col lg={12} md={12}>
                          <TextInput
                            as="textarea"
                            rows="4"
                            inputClass="scrollable"
                            label={t('voucherForm.comments')}
                            name="comment"
                            value={values.comment}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={12} md={12}>
                          <Row>
                            <Col lg={3} md={3}>
                              <TextInput
                                isRequired
                                label={t('voucherForm.voucherCode')}
                                placeholder={t('voucherForm.enterVoucherCode')}
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                error={errors.code && touched.code ? errors.code : null}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <div className="app-setting-btn--block">
                        <Button type="submit" className="" disabled={isSubmitting}>
                          {voucherId ? 'Edit' : 'Add'} {t('button.addVoucher')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default VoucherForm;
