import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { APP_USER_DETAILS_LIST, DOWNLOAD_APP_USER_DETAIL_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { roundOfDigit } from 'components/common/utils';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import ReactPagination from 'components/general/ReactPagination';
import { Formik, Form } from 'formik';
import Select from 'components/inputs/Select';
import { MdClear } from 'react-icons/md';
import OffCanvas from 'components/inputs/OffCanvas';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';


const AppUserDetailsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [device_type, setDeviceType] = useState('');
  const allAppUserDetailsList = useSelector((state) => state.appUserDetailsList.appUserDetailsLists);
  const isLoader = useSelector((state) => state.appUserDetailsList.isLoading);
  const page = useSelector((state) => state.appUserDetailsList.page);
  const totalData = useSelector((state) => state.appUserDetailsList.total);
  const limit = useSelector((state) => state.appUserDetailsList.limit);
  const totalPages = useSelector((state) => state.appUserDetailsList.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment().tz(countryTimezone).startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));

  const appUserDetailsList = useCallback(
    (value) => {
      if (searchText) {
        const appUserDetailsListData = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          search: searchText,
        };
        dispatch({
          type: APP_USER_DETAILS_LIST.REQUEST,
          payload: appUserDetailsListData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: APP_USER_DETAILS_LIST.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText, dispatch]
  );

  const downloadappUserDetaillist = useCallback(() => {
    if (device_type) {
      const data = {
        ...device_type,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        excel: true,
      };
      dispatch({
        type: DOWNLOAD_APP_USER_DETAIL_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'App user detail'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        excel: true,
      };
      dispatch({
        type: DOWNLOAD_APP_USER_DETAIL_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'App user detail'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, device_type]);

  const downloadFile = () => {
    downloadappUserDetaillist();
  };

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    appUserDetailsList({});
  };

  useEffect(() => {
    appUserDetailsList();
  }, []);

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (device_type) {
      const filterDateData = {
        ...device_type,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      appUserDetailsList(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      appUserDetailsList(filterDateData);
    }
  }, [startDate, endDate, sortByItem, searchText, device_type]);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && appUserDetailsList(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        appUserDetailsList(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && appUserDetailsList(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        appUserDetailsList(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (device_type) {
        const data = {
          ...device_type,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        appUserDetailsList(data);
      } else if (startDate && endDate && searchText) {
        const data = {
          search: searchText,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        appUserDetailsList(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        appUserDetailsList(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        appUserDetailsList(data);
      }
    },
    [sortByItem, startDate, endDate, searchText, device_type]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  const initialValues = !_.isEmpty(allAppUserDetailsList) ? { ...allAppUserDetailsList } : { device_type: '' };

  // Function to clear filters and search text
  const clearFilters = () => {
    setStartDate(moment().startOf('month'));
    setEndDate(moment(new Date()).endOf('day'));
    setDeviceType('');
    setSearchText('');
    setShowAdvanceFilterPopup(false);
    appUserDetailsList();
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.appUserDetailsList')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.name')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.mobileNumber')}</span>
                                <span className="ico" onClick={() => handleSorting('phone', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.email')}</span>
                                <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.rfidCardNo')}</span>
                                <span className="ico">
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.deviceType')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.walletBalance')}</span>
                                <span className="ico" onClick={() => handleSorting('wallet_balance', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addAppUserDetailList.totalVehicle')}</span>
                                <span className="ico" onClick={() => handleSorting('vehicles', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allAppUserDetailsList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('addAppUserDetailList.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(allAppUserDetailsList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <>
                                  <tr key={`app-user-details-list-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'name', 'Guest User')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'phone', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'email', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item.rfid[[0]], 'card_number', '')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>
                                          {!item.androidToken && !item.iosToken
                                            ? 'InActive'
                                            : item.androidToken && item.iosToken
                                              ? 'Both'
                                              : item.androidToken
                                                ? 'Android'
                                                : 'ios'}
                                        </>
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'wallet_balance', ''), 2)}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'vehicles', '').length}</td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allAppUserDetailsList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* App User Details Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              const device_type = { device: values.device_type, isActive: values.isActive };
              setDeviceType(device_type);
              appUserDetailsList(device_type);
              setSubmitting(false);
              onCloseAdvanceFilterPopup();
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.deviceType')}
                    placeholder={t('placeHolder.selectdeviceType')}
                    options={[
                      { label: 'Both', value: 'all' },
                      { label: 'Android', value: 'android' },
                      { label: 'IoS', value: 'ios' },
                    ]}
                    name="device_type"
                    value={values.device_type}
                    onMenuScrollDown={true}
                    onChange={(val) => setFieldValue('device_type', val)}
                  />
                  <Select
                    label={t('filters.isActive')}
                    options={[
                      { label: 'true', value: 'true' },
                      { label: 'false', value: 'false' },
                    ]}
                    placeholder={t('placeHolder.selectAnyone')}
                    name="isActive"
                    value={values.isActive}
                    onChange={(val) => {
                      setFieldValue('isActive', val);
                    }}
                  />
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      resetForm();
                      clearFilters();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default AppUserDetailsList;
