import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { AiOutlineDownload } from 'react-icons/ai';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { DOWNLOAD_USER_CHARGING_HISTORY, USER_CHARGING_HISTORY } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { roundOfDigit } from 'components/common/utils';
//import { millisecondsToHourMinute, roundOfDigit } from 'components/common/utils';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { BsDownload } from 'react-icons/bs';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { IoSearchOutline } from 'react-icons/io5';
import fileDownload from 'js-file-download';
import ReactPagination from 'components/general/ReactPagination';
import { Formik, Form } from 'formik';
import Select from 'components/inputs/Select';
import { MdClear } from 'react-icons/md';
import OffCanvas from 'components/inputs/OffCanvas';
import { FETCH_CPO } from 'actions/partnerManagement';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';


const UserChargingHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  // const [fieldValue, setFieldValue] = useState('All');
  // const [allUserChargingHistoryData, setAllUserChargingHistoryData] = useState([]);
  const [isOcpiPartyId, setIsOcpiPartyId] = useState('false');

  const userChargingHistoryList = useSelector((state) => state.userChargingHistory.userChargingHistories);
  const isLoader = useSelector((state) => state.userChargingHistory.isLoading);
  const page = useSelector((state) => state.userChargingHistory.page);
  const totalData = useSelector((state) => state.userChargingHistory.total);
  const limit = useSelector((state) => state.userChargingHistory.limit);
  const totalPages = useSelector((state) => state.userChargingHistory.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));
  const [selectedOption, setSelectedOption] = useState('station_name');
  const searchOptions = [
    { label: 'Username', value: 'username' },
    { label: 'Charger ID', value: 'charger_id' },
    { label: 'Mobile Number', value: 'phone' },
    { label: 'Station Name', value: 'station_name' },
  ];

  const userChargingHistory = useCallback(
    (value) => {
      if (searchText) {
        const userChargingHistoryData = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          status: 'completed',
          search: {
            [selectedOption]: searchText,
          },
        };
        dispatch({
          type: USER_CHARGING_HISTORY.REQUEST,
          payload: userChargingHistoryData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          status: 'completed',
        };
        dispatch({
          type: USER_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText, selectedOption]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      const data = {
        ...advanceFilterData,
        page: page.selected + 1,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      userChargingHistory(data);
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      userChargingHistory(filterDateData);
    }
  }, [startDate, endDate, sortByItem, searchText, selectedOption, advanceFilterData]);

  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && userChargingHistory(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && userChargingHistory(oldestData);
  //   fieldValue === 'All' && userChargingHistory();
  // }, [fieldValue]);

  // useEffect(() => {
  //   setAllUserChargingHistoryData(
  //     _.filter(
  //       userChargingHistoryList,
  //       (item) =>
  //         startsWith(_.get(item, 'customer_user_booked.name'), searchText) ||
  //         startsWith(_.get(item, 'charger.charging_station.name'), searchText) ||
  //         startsWith(_.get(item, 'customer_user_booked.phone').toString(), searchText) ||
  //         startsWith(_.get(item, 'transaction_id'), searchText) ||
  //         startsWith(_.get(item, 'charger.per_unit_rates'), searchText) ||
  //         startsWith(_.get(item, 'charger.charger_id'), searchText) ||
  //         startsWith(roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'invoice.amount', '-'), 2).toString(), searchText) ||
  //         startsWith(millisecondsToHourMinute(_.get(item, 'time_taken')).toString(), searchText) ||
  //         startsWith(moment(_.get(item, 'schedule_datetime')).format('DD/MM/YYYY  H:mm:ss'), searchText) ||
  //         startsWith(_.get(item, 'vehicle.make', ''), searchText) ||
  //         startsWith(_.get(item, 'vehicle.model', ''), searchText)
  //     )
  //   );
  // }, [searchText, userChargingHistoryList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    userChargingHistory({});
  };

  useEffect(() => {
    userChargingHistory();
  }, []);

  // Download Excel Functionality

  const downloadUserChargingHistory = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      const data = {
        ...advanceFilterData,
        excel: true,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        report: 'usercharginghistory',
        status: 'completed',
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      dispatch({
        type: DOWNLOAD_USER_CHARGING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'User Charging history'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        report: 'usercharginghistory',
        status: 'completed',
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      dispatch({
        type: DOWNLOAD_USER_CHARGING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'User Charging history'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadUserChargingHistory();
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && userChargingHistory(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        userChargingHistory(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && userChargingHistory(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        userChargingHistory(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        const data = {
          ...advanceFilterData,
          page: page.selected + 1,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        userChargingHistory(data);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          search: {
            [selectedOption]: searchText,
          },
          page: page.selected + 1,
        };
        userChargingHistory(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        userChargingHistory(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        userChargingHistory(data);
      }
    },
    [sortByItem, startDate, endDate, searchText, advanceFilterData, selectedOption]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const search = (
    <div className="search-box-option">
      <Select options={searchOptions} value={selectedOption} onChange={(value) => setSelectedOption(value)} placeholder="Search Option" />
      <SearchBox
        preIcon={<BsSearch />}
        value={searchText}
        onChange={searchHandler}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </div>
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const ocpidata = useSelector((state) => state.partnerManagement.partnerDetails);
  const ocpiPage = useSelector((state) => state.partnerManagement.page);
  const ocpiTotalPages = useSelector((state) => state.partnerManagement.totalPages);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { is_ocpi_based_booking: '', partyId: '' };

  const getOcpidata = useCallback(
    (data) =>
      dispatch({
        type: FETCH_CPO.REQUEST,
        payload: data,
      }),
    []
  );

  useEffect(() => {
    getOcpidata({ limit: 999 });
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.userChargingHistory')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      {/* <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).startOf('day')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      {/* <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col> */}
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block">
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon" onClick={downloadFile}>
                            {/* <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="User Charging History"
                            sheet="User Charging History"
                            buttonText={<PiMicrosoftExcelLogo size={28} />}
                          /> */}
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                      {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.userName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.mobileNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.transactionId')}</span>
                                <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stationName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.chargerId')}</span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : 'Party Id'}</span>
                              </div>
                            </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.unitConsumed')}</span>
                                <span className="ico" onClick={() => handleSorting('energy_consumed', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.energyRate')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.sessionDuration')}</span>
                                <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stopReason')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.netAmount')}</span>
                              </div>
                            </th>
                            {/* <th>
                            <div className="sorting">
                              <span>CGST Amount (INR)</span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>SGST Amount (INR)</span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>Offer Amount (INR)</span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>Total Amount Paid (INR)</span>
                            </div>
                          </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.dateTime')}</span>
                                <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.makeModel')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(userChargingHistoryList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('dataReport.noUserChargingHistoryData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(userChargingHistoryList, (item, key) => {
                              const connectorData = _.find(_.get(item, 'charger.plugs', []), { connector_id: item.connectorId });
                              const unitConsumed = isNaN((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000)
                                ? ''
                                : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);
                              const pricePerUnit = unitConsumed
                                ? isNaN(parseFloat(_.get(item, 'invoice.subtotal') / unitConsumed).toFixed(2))
                                  ? ''
                                  : parseFloat(_.get(item, 'invoice.subtotal') / unitConsumed).toFixed(2)
                                : '';
                              const serial_num = limit * (page - 1) + key;
                              function calculateTimeDifference(start, end) {
                                const startTime = moment(start);
                                const stopTime = moment(end);

                                const timeDiffInMS = stopTime - startTime;

                                const seconds = moment.duration(timeDiffInMS).seconds();
                                const minutes = moment.duration(timeDiffInMS).minutes();
                                const hours = Math.trunc(moment.duration(timeDiffInMS).asHours());

                                if (Math.sign(timeDiffInMS) === -1) {
                                  return `-${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                } else {
                                  return `${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                }
                              }
                              return (
                                <tr key={`user-charging-history-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.name', 'Guest User')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.phone')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>
                                        {_.get(item, 'charger.charger_id', '')} ({_.get(connectorData, 'name', '')})
                                      </>
                                    )}
                                  </td>
                                  {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.partyId', '-')}</td> */}
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : unitConsumed}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.price_per_unit', pricePerUnit)}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>{calculateTimeDifference(item.booking_start, item.booking_stop)}</>
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <>
                                        {_.has(item, 'invoice.service_charge')
                                          ? roundOfDigit(_.get(item, 'invoice.service_charge', ''), 2)
                                          : roundOfDigit(_.get(item, 'invoice.subtotal', ''), 2)}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'booking_start') ? (
                                      moment(_.get(item, 'booking_start')).format('DD/MM/YYYY  H:mm:ss')
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      _.get(item, 'vehicle.make', '') + (item.vehicle ? `(${_.get(item, 'vehicle.model', '')})` : '')
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(userChargingHistoryList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* App User Details Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.is_ocpi_based_booking && delete values['is_ocpi_based_booking'];
              !values.partyId && delete values['partyId'];
              if (values.is_ocpi_based_booking === 'true') {
                const userChargingHistoryData = { ...values, is_ocpi_based_booking: 'true' };
                userChargingHistory(userChargingHistoryData);
              } else if (values.is_ocpi_based_booking === 'false') {
                const userChargingHistoryData = { ...values, is_ocpi_based_booking: 'false', partyId: '' };
                userChargingHistory(userChargingHistoryData);
              } else {
                userChargingHistory(values);
              }

              handleAdvanceFilterData(values);
              setSubmitting(false);
              onCloseAdvanceFilterPopup();
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.ocpiBooking')}
                    placeholder={t('placeHolder.selectAnyone')}
                    options={[
                      { label: 'true', value: 'true' },
                      { label: 'false', value: 'false' },
                    ]}
                    name="is_ocpi_based_booking"
                    value={values.is_ocpi_based_booking}
                    onMenuScrollDown={true}
                    onChange={(val) => {
                      setFieldValue('is_ocpi_based_booking', val);
                      setIsOcpiPartyId(val);
                    }}
                  />
                  {isOcpiPartyId == 'true' ? (
                    <Select
                      label={t('filters.ocpiParties')}
                      options={[
                        ..._.map(ocpidata, (data) => {
                          return { label: data.partyId, value: data.partyId };
                        }),
                      ]}
                      placeholder={t('placeHolder.selectParty')}
                      name="partyId"
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getOcpidata}
                      page={ocpiPage}
                      totalPage={ocpiTotalPages}
                      value={values.partyId}
                      onChange={(val) => {
                        setFieldValue('partyId', val);
                      }}
                    />
                  ) : null}
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      resetForm();
                      userChargingHistory();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default UserChargingHistory;
