import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import SearchBox from 'components/general/SearchBox';
import { Col, Row } from 'react-bootstrap';
import { BsFilter } from 'react-icons/bs';
import * as _ from 'lodash';
import Avatar from 'components/inputs/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import DummyImage from 'assets/images/icons/dummyImage.png';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
import DropDown from 'components/inputs/DropDown';
import moment from 'moment-timezone';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { FETCH_TENANT } from 'actions/tenant';
import Button from 'components/inputs/Button';
// import { useHistory } from 'react-router-dom';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';


const SubscriptionList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  // const [allSubscriptionData, setAllSubscriptionData] = useState([]);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const allTenantSubscriptions = useSelector((state) => state.tenant.tenants);
  const page = useSelector((state) => state.tenant.page);
  const totalData = useSelector((state) => state.tenant.total);
  const limit = useSelector((state) => state.tenant.limit);
  const totalPages = useSelector((state) => state.tenant.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment().tz(countryTimezone).startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));

  const getTenant = useCallback(
    (data = {}) => {
      if (searchText) {
        const tenantData = {
          ...data,
          name: searchText,
        };
        dispatch({ type: FETCH_TENANT.REQUEST, payload: tenantData });
      } else {
        dispatch({ type: FETCH_TENANT.REQUEST, payload: data });
      }
    },
    [searchText]
  );

  // useEffect(() => {
  //   getTenant();
  // }, []);

  // const dateFilter = useCallback(() => {
  //   const filterDateData = {
  //     from: moment(startDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
  //     to: moment(endDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
  //   };
  //   endDate && getTenant(filterDateData);
  // }, [startDate, endDate]);

  // useEffect(() => {
  //   const tenantSubscriptionData = _.filter(allTenantSubscriptions, (item) => !_.isEmpty(item.licenses));
  //   setAllSubscriptionData(
  //     _.filter(
  //       tenantSubscriptionData,
  //       (item) => startsWith(item.name, searchText) || startsWith(_.get(_.head(item.licenses), 'name', ''), searchText)
  //     )
  //   );
  // }, [searchText, allTenantSubscriptions]);

  useEffect(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
      };
      endDate && getTenant(filterDateData);
    }
  }, [startDate, endDate, sortByItem, searchText]);

  const getSortByItem = (name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page && startDate && endDate) {
      const data = {
        from: moment(startDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      endDate && getTenant(data);
    } else if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getTenant(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      endDate && getTenant(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getTenant(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        const filterDateData = {
          from: moment(startDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
        };
        getSortByItem(sortByItem.item, sortByItem.order, page, filterDateData);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz(countryTimezone).format('YYYY-MM-DD HH:mm:ss'),
          page: page.selected + 1,
        };
        getTenant(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getTenant(data);
      }
    },
    [sortByItem, startDate, endDate]
  );

  const handleSearch = () => {
    getTenant({});
  };

  const newestData = () => {
    const newData = {
      sortBy: '-license_expiry',
    };
    getTenant(newData);
  };

  const oldestData = () => {
    const oldData = {
      sortBy: 'license_expiry',
    };
    getTenant(oldData);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.subscriptions')} />
        <div className="page-content-wrapper scrollable">
          <div className="subscriptions-page_main">
            <Card>
              <div className="subscriptions-page__inner">
                <div className="page-top--filter-header">
                  <Row>
                    <Col xl={4} md={12}>
                      <SearchBox
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col xl={1} md={2} className="subscription-filter--box">
                      <div className="subscriptions-filter">
                        <DropDown
                          popupClass="bottom-left"
                          trigger={
                            <div className="">
                              <BsFilter />
                            </div>
                          }
                          triggerClass=""
                        >
                          <ul>
                            <li className="item" onClick={newestData}>
                              {t('subscriptionList.newest')}
                            </li>
                            <li className="item" onClick={oldestData}>
                              {t('subscriptionList.oldest')}
                            </li>
                            <li className="item" onClick={() => getTenant()}>
                              {t('subscriptionList.all')}
                            </li>
                          </ul>
                        </DropDown>
                      </div>
                    </Col>
                    <Col xl={5} md={10}>
                      {/* <DatePickerWithFloatingLabel
                        defaultValueOfStartDate={new Date()}
                        defaultValueOfEndDate={new Date()}
                      // onChangeOfStartDate={(item) => setStartDate(item)}
                      // onChangeOfEndDate={(item) => setEndDate(item)}
                      /> */}
                      <DateTimePicker
                        onChangeOfStartDate={(item) => setStartDate(item)}
                        onChangeOfEndDate={(item) => setEndDate(item)}
                        initialValueOfStartDate={moment().startOf('month')}
                        initialValueOfEndDate={moment(new Date()).endOf('day')}
                      />
                    </Col>
                    <Col xl={2} lg={3} md={4} className="subscriptionList-search-boxButton">
                      <Button className="subscriptionList-search-box-Btn" onClick={() => navigateTo('/addTenant')}>
                        {t('button.addSubscription')}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="subscription-list-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('subscriptionList.tenantAdmin')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('subscriptionList.subscriptionTitle')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('subscriptionList.type')}</span>
                              <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('subscriptionList.amount')}</span>
                              <span className="ico" onClick={() => handleSorting('amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('subscriptionList.licenseExpiry')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allTenantSubscriptions) ? (
                          <tr>
                            <td colSpan={8} className="border-0">
                              <div className="empty-data-block">{t('subscriptionList.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allTenantSubscriptions, (subscription, index) => {
                            const subscriptionDetail = _.head(subscription.licenses) || {};
                            return (
                              <>
                                <tr key={`subscription-${index}`}>
                                  <td>
                                    <div className="subscription-name-list">
                                      <Avatar imgSrc={DummyImage} className="subscription-avatar" name={''} />
                                      <div className="user-info--name" onClick={() => navigateTo(`/tenantAdmin/${_.get(subscription, 'id', '')}`)}>
                                        <span>{_.get(subscription, 'name', '')}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{_.get(subscriptionDetail, 'name', '')}</td>
                                  <td>{_.get(subscriptionDetail, 'type', '')}</td>
                                  <td>{_.get(subscriptionDetail, 'amount', '')}</td>
                                  <td>
                                    <span>{subscription.license_expiry ? moment(subscription.license_expiry).format('DD MMM, YYYY') : ''}</span>
                                    {/* <span>
                                      {moment(_.get(subscription, 'start_date', '')).format('DD')} to{' '}
                                      {moment(_.get(subscription, 'expiry_date', '')).format('DD MMM. YYYY')}
                                    </span> */}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!_.isEmpty(allTenantSubscriptions) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default SubscriptionList;
