import { FETCH_VOUCHERS, DELETE_VOUCHER, UPDATE_VOUCHER, CREATE_VOUCHER, GET_VOUCHER, VOUCHER_LOGS } from 'actions/voucher';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  vouchers: [],
  voucherDetail: {},
  voucherLogs: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const voucherReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_VOUCHER.REQUEST:
    case DELETE_VOUCHER.REQUEST:
    case FETCH_VOUCHERS.REQUEST:
    case CREATE_VOUCHER.REQUEST:
    case GET_VOUCHER.REQUEST:
    case VOUCHER_LOGS.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_VOUCHERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vouchers: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_VOUCHER.SUCCESS: {
      return {
        ...state,
        voucherDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_VOUCHER.SUCCESS: {
      toast.success('Voucher added successfully');
      return {
        ...state,
        vouchers: _.concat(state.vouchers, payload),
        isLoading: false,
      };
    }

    case VOUCHER_LOGS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        voucherLogs: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case UPDATE_VOUCHER.SUCCESS: {
      toast.success('Voucher updated successfully');
      return {
        ...state,
        isLoading: false,
        vouchers: state.vouchers.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_VOUCHER.SUCCESS: {
      toast.success('Voucher deleted successfully');
      return {
        ...state,
        vouchers: state.vouchers.filter((data) => data.id !== payload),
      };
    }

    case DELETE_VOUCHER.FAIL:
    case UPDATE_VOUCHER.FAIL:
    case FETCH_VOUCHERS.FAIL:
    case CREATE_VOUCHER.FAIL:
    case GET_VOUCHER.FAIL:
    case VOUCHER_LOGS.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};

export default voucherReducer;
