import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import { VOUCHER_LOGS, DOWNLOAD_VOUCHER_LOGS, FETCH_VOUCHERS } from 'actions/voucher';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
//import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { FaFilter } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import { FETCH_CUSTOMER } from 'actions/customer';

const VoucherLogs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeAssignee, setAssignee] = useState(false);
  // const [changeVoucher, setChangeVoucher] = useState(false);
  const allUsers = useSelector((state) => state.customer.customers);
  const allvouchers = useSelector((state) => state.voucher.vouchers);
  const voucherLogsList = useSelector((state) => state.voucher.voucherLogs);
  const isLoader = useSelector((state) => state.voucher.isLoading);
  const page = useSelector((state) => state.voucher.page);
  const totalData = useSelector((state) => state.voucher.total);
  const limit = useSelector((state) => state.voucher.limit);
  const totalPages = useSelector((state) => state.voucher.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');

  const voucherLogsReport = useCallback(
    (value) => {
      if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
        };
        dispatch({
          type: VOUCHER_LOGS.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: VOUCHER_LOGS.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        voucherLogsReport(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        voucherLogsReport(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      voucherLogsReport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      voucherLogsReport(filterDateData);
    }
  }, [startDate, endDate, advanceFilterData]);

  const getAllUsers = useCallback((data) => {
    const userData = {
      ...data,
      limit: 99999,
    };
    dispatch({ type: FETCH_CUSTOMER.REQUEST, payload: userData });
  }, []);

  const getAllVouchers = useCallback((data) => {
    const userData = {
      ...data,
      limit: 99999,
    };
    dispatch({ type: FETCH_VOUCHERS.REQUEST, payload: userData });
  }, []);

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      voucherLogsReport(data);
    } else {
      voucherLogsReport();
    }
    getAllUsers();
    getAllVouchers();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          voucherLogsReport(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          voucherLogsReport(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        voucherLogsReport(filterDateData);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        voucherLogsReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        voucherLogsReport(data);
      }
    },
    [startDate, endDate, advanceFilterData]
  );

  const downloadvoucherLogsReport = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          excel: true,
        };
        dispatch({
          type: DOWNLOAD_VOUCHER_LOGS.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Voucher Log'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          excel: true,
        };
        dispatch({
          type: DOWNLOAD_VOUCHER_LOGS.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Voucher Log'}.xlsx`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
        excel: true,
      };
      dispatch({
        type: DOWNLOAD_VOUCHER_LOGS.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Voucher Log'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        excel: true,
      };
      dispatch({
        type: DOWNLOAD_VOUCHER_LOGS.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Voucher Log'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadvoucherLogsReport();
  };
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { assginee: '' };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.voucherLog')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={'auto'} md={'auto'}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <div className="advance-filter-btn">
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.name')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.code')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.redemptionType')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.discountAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.assignee')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.assigneePhone')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.assigner')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.comment')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherLogsReport.createdAt')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(voucherLogsList) ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="empty-data-block">{t('voucherLogsReport.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(voucherLogsList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              const phoneNumber = _.get(item, 'assignee.phone')
                                ? `+${_.get(item, 'assignee.country_code', ' ')} ${_.get(item, 'assignee.phone', ' ')}`
                                : '';
                              return (
                                <tr key={`coupon-usage-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.code', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.redemption_type', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.discount_amount', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'assignee.name', '') || 'Guest User'}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : phoneNumber}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.assigner.name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.comment', '')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.createdAt ? (
                                      moment(item.createdAt).format('DD/MM/YYYY  H:mm:ss')
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(voucherLogsList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              let submissionValues = { ...values };

              if (!submissionValues.assignee) {
                delete submissionValues.assignee;
              } else if (Array.isArray(submissionValues.assignee)) {
                if (submissionValues.assignee.includes('All')) {
                  submissionValues.assignee = !_.isEmpty(userProfileStations) ? userProfileStations.join(',') : '';
                } else {
                  submissionValues.assignee = submissionValues.assignee.join(',');
                }
              } else if (submissionValues.assignee === 'All') {
                submissionValues.assignee = !_.isEmpty(userProfileStations) ? userProfileStations.join(',') : '';
              }
              if (!submissionValues.voucher) {
                delete submissionValues.voucher;
              } else if (Array.isArray(submissionValues.voucher)) {
                submissionValues.voucher = submissionValues.voucher.join(',');
              }
              voucherLogsReport(submissionValues);
              handleAdvanceFilterData(submissionValues);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.assignee')}
                      options={
                        !_.isEmpty(allUsers)
                          ? !changeAssignee
                            ? !_.isEmpty(allUsers) && [
                                { label: 'All', value: 'All' },
                                ..._.map(allUsers, (user) => {
                                  return { label: `${_.get(user, 'name')} +${_.get(user, 'country_code')} ${_.get(user, 'phone')}`, value: user.id };
                                }),
                              ]
                            : !_.isEmpty(allUsers) && [{ label: 'All', value: 'All' }]
                          : !changeAssignee
                            ? !_.isEmpty(allUsers) && [
                                { label: 'All', value: 'All' },
                                ..._.map(allUsers, (user) => {
                                  return { label: user.phone, value: user.id };
                                }),
                              ]
                            : !_.isEmpty(allUsers) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectAssignee')}
                      name="assignee"
                      s
                      value={values.assignee}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setAssignee(true);
                          setFieldValue(`assignee`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`assignee`, '');
                          setAssignee(false);
                        } else {
                          setAssignee(false);
                          setFieldValue('assignee', val);
                        }
                      }}
                    />
                    <Select
                      label={t('filters.voucher')}
                      options={
                        !_.isEmpty(allvouchers) && [
                          ..._.map(allvouchers, (item) => {
                            return { label: item.name, value: item.id };
                          }),
                        ]
                      }
                      placeholder={t('placeHolder.selectVoucher')}
                      name="voucher"
                      value={values.voucher}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue('voucher', val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      voucherLogsReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="clear--icon" />
                    {t('button.clear')}
                    {/* Reset */}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default VoucherLogs;
