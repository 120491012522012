import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { millisecondsToHourMinute, roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_STATION_CHARGING_SUMMARY, STATION_CHARGING_SUMMARY } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Button from 'components/inputs/Button';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { IoSearchOutline } from 'react-icons/io5';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';

const General = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [cityLimit, setCityLimit] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [changeStationType, setChangeStationType] = useState(false);

  const stationChargingSummaryList = useSelector((state) => state.stationChargingSummary.stationChargingSummaries);
  const isLoader = useSelector((state) => state.stationChargingSummary.isLoading);
  const page = useSelector((state) => state.stationChargingSummary.page);
  const totalData = useSelector((state) => state.stationChargingSummary.total);
  const limit = useSelector((state) => state.stationChargingSummary.limit);
  const totalPages = useSelector((state) => state.stationChargingSummary.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment().tz(countryTimezone).startOf('year'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));
  const userProfileStations = profileData.charging_stations;
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const stationChargingSummary = useCallback(
    (value) => {
      if (searchText) {
        const stationChargingSummaryData = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          search: searchText,
        };
        dispatch({
          type: STATION_CHARGING_SUMMARY.REQUEST,
          payload: stationChargingSummaryData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        ocpiCredential: _.get(ocpiData, 'id'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (advanceFilterData) {
      if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '', access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, state: '', access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      }
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        ocpiCredential: _.get(ocpiData, 'id'),
      };
      stationChargingSummary(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData]);

  // Download Excel Functionality

  const downloadStationChargingSummary = useCallback(() => {
    if (advanceFilterData) {
      if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '', access_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, state: '', access_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
              }
            }
          },
        });
      }
    } else {
      const data = {
        excel: true,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        ocpiCredential: _.get(ocpiData, 'id'),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      dispatch({
        type: DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Station Charging Summary'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadStationChargingSummary();
  };

  useEffect(() => {
    stationChargingSummary();
  }, [searchText]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    stationChargingSummary({});
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && stationChargingSummary(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          page: page.selected + 1,
        };
        stationChargingSummary(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        endDate && stationChargingSummary(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
        };
        stationChargingSummary(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (advanceFilterData) {
        if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '', access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, state: '', city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, state: '', access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        } else if (advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            ocpiCredential: _.get(ocpiData, 'id'),
          };
          stationChargingSummary(data);
        }
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          search: searchText,
          page: page.selected + 1,
        };
        stationChargingSummary(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          page: page.selected + 1,
        };
        stationChargingSummary(data);
      } else {
        const data = {
          is_ocpi_based_booking: true,
          ocpiCredential: _.get(ocpiData, 'id'),
          page: page.selected + 1,
        };
        stationChargingSummary(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const totalStateData = useSelector((state) => state.state.total);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const totalCityData = useSelector((state) => state.cityByState.total);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { country: '', state: '', city: '', access_type: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  useEffect(() => {
    if (showAdvanceFilterPopup) {
      getStateByCountry({ country_name: country, limit: totalStateData });
    }
    if (cityLimit) {
      getCityByStateName({ state_names: state, limit: totalCityData });
    }
  }, [country, state, totalStateData, totalCityData, cityLimit]);

  useEffect(() => {
    getAllCountry();
  }, []);

  const allStationType = [
    { label: 'Public', value: 'Public' },
    { label: 'Private', value: 'Private' },
    { label: 'Commercial', value: 'Commercial' },
  ];

  return (
    <>
      <div className="data-report__inner">
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="data-report__box">
            <Row className="data-report__row">
              <Col
                xl={`${showSearchBar ? 'auto' : 'auto'}`}
                md={`${showSearchBar ? 'auto' : 'auto'}`}
                className={`${showSearchBar ? '' : 'data-report-search-box'}`}
              >
                {showSearchBar ? search : searchIcon}
              </Col>
              <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                <div className="data-report-date_picker">
                  <DateTimePicker
                    onChangeOfStartDate={(item) => setStartDate(item)}
                    onChangeOfEndDate={(item) => setEndDate(item)}
                    initialValueOfStartDate={moment().startOf('year')}
                    initialValueOfEndDate={moment(new Date()).endOf('day')}
                  />
                </div>
              </Col>
              <Col xl={'auto'} md={'auto'}>
                <div className="report-search-box" onClick={dateFilter}>
                  <Button className="report-search-button">{t('button.search')}</Button>
                </div>
              </Col>
              <Col xl={'auto'} md={'auto'}>
                <div className="report-search-excel-block">
                  <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                    <Button className="report-search-button">
                      <FaFilter className="hide-lap" />
                      <span>{t('button.advancedFilter')}</span>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xl={'auto'} md={'auto'} className="excel-icon--block">
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <div className="report-excel-icon" onClick={downloadFile}>
                    <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <div className="data-report__table">
            <div className="table-responsive">
              <table className="record-list-table" id="table-to-xls">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stationName')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.ocppID')}</span>
                        <span className="ico" onClick={() => handleSorting('ocpp_id', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stationType')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.rfidBasedBooking')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.ocpiBasedBooking')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.unitConsumed')}</span>
                        <span className="ico" onClick={() => handleSorting('energy_consumed', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.sessionDuration')}</span>
                        <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.netAmount')}</span>
                        <span className="ico" onClick={() => handleSorting('amount', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.noofsessions')}</span>
                        <span className="ico" onClick={() => handleSorting('number_of_sessions', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.noOfEVServed')}</span>
                        <span className="ico" onClick={() => handleSorting('ev_served', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoader ? (
                    <tr>
                      <td colSpan={10} className="border-0">
                        <div className="loader--block">
                          <Skeleton height={15} width={100} />
                        </div>
                      </td>
                    </tr>
                  ) : _.isEmpty(stationChargingSummaryList) ? (
                    <tr>
                      <td colSpan={10} className="border-0">
                        <div className="empty-data-block">{t('dataReport.noStationChargingSummaryData')}</div>
                      </td>
                    </tr>
                  ) : (
                    !(isLoader || _.isUndefined(isLoader)) &&
                    _.map(
                      _.filter(stationChargingSummaryList, (item) => item !== null),
                      (item, key) => {
                        const rfidBasedBooking = _.get(item, 'rfid_based', '-');
                        const ocpiBasedBooking = _.get(item, 'ocpi_based', '-');
                        const serial_num = limit * (page - 1) + key;
                        return (
                          <>
                            <tr key={`station-charging-summary-${serial_num}`}>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charging_station.name', '-')}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpp_id', '-')}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charging_station.access_type', '-')}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : rfidBasedBooking}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : ocpiBasedBooking}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'energy_consumed'), 3)}</td>
                              <td>
                                {isLoader ? <Skeleton height={15} width={100} /> : millisecondsToHourMinute(_.get(item, 'time_taken'))}:
                                {moment.duration(_.get(item, 'time_taken')).seconds()}
                              </td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'amount', '-'), 2)}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'number_of_sessions')}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ev_served')}</td>
                            </tr>
                          </>
                        );
                      }
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(stationChargingSummaryList) && (
            <ReactPagination
              currentPage={page}
              limit={limit}
              total={totalData}
              handlePageClick={(pageVal) => handlePageClick(pageVal)}
              totalPages={totalPages}
              marginPagesDisplayed={1}
            />
          )}
        </SkeletonTheme>
      </div>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.access_type && delete values['access_type'];
              if (values.state === 'All' && values.city === 'All' && values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, state: '', city: '', access_type: '' };
                stationChargingSummary(stationChargingSummaryData);
              } else if (values.state === 'All' && values.city === 'All') {
                const stationChargingSummaryData = { ...values, state: '', city: '' };
                stationChargingSummary(stationChargingSummaryData);
              } else if (values.state === 'All' && values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, state: '', access_type: '' };
                stationChargingSummary(stationChargingSummaryData);
              } else if (values.city === 'All' && values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, access_type: '', city: '' };
                stationChargingSummary(stationChargingSummaryData);
              } else if (values.city === 'All') {
                const stationChargingSummaryData = { ...values, city: '' };
                stationChargingSummary(stationChargingSummaryData);
              } else if (values.state === 'All') {
                const stationChargingSummaryData = { ...values, state: '' };
                stationChargingSummary(stationChargingSummaryData);
              } else if (values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, access_type: '' };
                stationChargingSummary(stationChargingSummaryData);
              } else {
                stationChargingSummary(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.country')}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectCountry')}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      // setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.state')}
                    options={
                      !changeState
                        ? values.country &&
                          !_.isEmpty(allState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectState')}
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.city')}
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                          !_.isEmpty(allCityByState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allCityByState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder={t('addCPO.selectCity')}
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`city`, '');
                        setChangeCity(false);
                      } else {
                        setChangeCity(false);
                        setFieldValue('city', val);
                      }
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label={t('addCPO.stationType')}
                      options={
                        !changeStationType
                          ? !_.isEmpty(allStationType) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStationType, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allStationType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStationType')}
                      name="access_type"
                      isMulti
                      value={values.access_type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStationType(true);
                          setFieldValue(`access_type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`access_type`, '');
                          setChangeStationType(false);
                        } else {
                          setChangeStationType(false);
                          setFieldValue('access_type', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('addCPO.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      stationChargingSummary();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default General;
