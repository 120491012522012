import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_VOUCHERS, DELETE_VOUCHER, UPDATE_VOUCHER, CREATE_VOUCHER, GET_VOUCHER, VOUCHER_LOGS, DOWNLOAD_VOUCHER_LOGS } from 'actions/voucher';

function* fetchVouchers(action) {
  try {
    const response = yield call(API.getVouchers, action.payload);
    yield put({ type: FETCH_VOUCHERS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_VOUCHERS.FAIL, payload: { error: e } });
  }
}

function* getVoucher(action) {
  try {
    const response = yield call(API.getVoucherById, action.payload);
    yield put({ type: GET_VOUCHER.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_VOUCHER.FAIL, payload: { error: e } });
  }
}

function* createVoucher(action) {
  try {
    const response = yield call(API.addVoucher, action.payload);
    yield put({ type: CREATE_VOUCHER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_VOUCHER.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateVoucher(action) {
  try {
    const response = yield call(API.updateVoucher, action.payload);
    yield put({ type: UPDATE_VOUCHER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VOUCHER.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteVoucher(action) {
  try {
    const response = yield call(API.deleteVoucher, action.payload);
    yield put({ type: DELETE_VOUCHER.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_VOUCHER.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchVoucherLogs(action) {
  try {
    const response = yield call(API.getVoucherLogs, action.payload);
    yield put({ type: VOUCHER_LOGS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: VOUCHER_LOGS.FAIL, payload: { error: e } });
  }
}

function* downloadVoucherLogs(action) {
  try {
    const response = yield call(API.downloadVoucherLogs, action.payload);
    yield put({ type: DOWNLOAD_VOUCHER_LOGS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_VOUCHER_LOGS.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* voucher() {
  yield takeLatest(FETCH_VOUCHERS.REQUEST, fetchVouchers);
  yield takeLatest(GET_VOUCHER.REQUEST, getVoucher);
  yield takeLatest(CREATE_VOUCHER.REQUEST, createVoucher);
  yield takeLatest(UPDATE_VOUCHER.REQUEST, updateVoucher);
  yield takeLatest(DELETE_VOUCHER.REQUEST, deleteVoucher);
  yield takeLatest(VOUCHER_LOGS.REQUEST, fetchVoucherLogs);
  yield takeLatest(DOWNLOAD_VOUCHER_LOGS.REQUEST, downloadVoucherLogs);
}

export default voucher;
