import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { BiSort } from 'react-icons/bi';
import { roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_MONEY_TRANSACTION_PG_REPORT, MONEY_TRANSACTION_PG_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import fileDownload from 'js-file-download';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';

const MoneyTransactionPGReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  // const [allMoneyTransactionPGReportData, setAllMoneyTransactionPGReportData] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [changePaymentStatus, setChangePaymentStatus] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const moneyTransactionPGReportList = useSelector((state) => state.moneyTransactionPgReport.moneyTransactionPgReports);
  const isLoader = useSelector((state) => state.moneyTransactionPgReport.isLoading);
  const page = useSelector((state) => state.moneyTransactionPgReport.page);
  const totalData = useSelector((state) => state.moneyTransactionPgReport.total);
  const limit = useSelector((state) => state.moneyTransactionPgReport.limit);
  const totalPages = useSelector((state) => state.moneyTransactionPgReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment().tz(countryTimezone).startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));

  const moneyTransactionPGReport = useCallback(
    (value) => {
      if (searchText) {
        const moneyTransactionPGReportData = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          search: searchText,
        };
        dispatch({
          type: MONEY_TRANSACTION_PG_REPORT.REQUEST,
          payload: moneyTransactionPGReportData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: MONEY_TRANSACTION_PG_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && moneyTransactionPGReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        moneyTransactionPGReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && moneyTransactionPGReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        moneyTransactionPGReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (advanceFilterData) {
      if (advanceFilterData.method === 'All' && advanceFilterData.status === 'All') {
        const filterData = { ...advanceFilterData, method: '', status: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        moneyTransactionPGReport(data);
      } else if (advanceFilterData.method === 'All') {
        const filterData = { ...advanceFilterData, method: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        moneyTransactionPGReport(data);
      } else if (advanceFilterData.status === 'All') {
        const filterData = { ...advanceFilterData, status: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        moneyTransactionPGReport(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        moneyTransactionPGReport(data);
      }
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      moneyTransactionPGReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (advanceFilterData) {
        if (advanceFilterData.method === 'All' && advanceFilterData.status === 'All') {
          const filterData = { ...advanceFilterData, method: '', status: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          moneyTransactionPGReport(data);
        } else if (advanceFilterData.method === 'All') {
          const filterData = { ...advanceFilterData, method: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          moneyTransactionPGReport(data);
        } else if (advanceFilterData.status === 'All') {
          const filterData = { ...advanceFilterData, status: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          moneyTransactionPGReport(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          moneyTransactionPGReport(data);
        }
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          search: searchText,
          page: page.selected + 1,
        };
        moneyTransactionPGReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        moneyTransactionPGReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        moneyTransactionPGReport(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );

  useEffect(() => {
    moneyTransactionPGReport();
  }, []);

  // useEffect(() => {
  //   setAllMoneyTransactionPGReportData(
  //     _.filter(
  //       moneyTransactionPGReportList,
  //       (item) =>
  //         startsWith(_.get(item, 'details.status'), searchText) ||
  //         startsWith(_.get(item, 'details.amount').toString(), searchText) ||
  //         startsWith(_.get(item, 'details.currency'), searchText) ||
  //         startsWith(_.get(item, 'details.created_at').toString(), searchText) ||
  //         startsWith(_.get(item, 'transaction_id'), searchText) ||
  //         startsWith(_.get(item, 'details.email'), searchText) ||
  //         startsWith(_.get(item, 'details.contact'), searchText) ||
  //         startsWith(_.get(item, 'details.order_id'), searchText) ||
  //         startsWith(_.get(item, 'details.method'), searchText) ||
  //         startsWith(_.get(item, 'details.bank'), searchText) ||
  //         startsWith(_.get(item, 'id'), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'details.amount') / 100, 2).toString(), searchText) ||
  //         startsWith(moment(_.get(item, 'createdAt')).format('DD/MM/YYYY H:mm:ss'), searchText)
  //     )
  //   );
  // }, [searchText, moneyTransactionPGReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const handleSearch = () => {
    moneyTransactionPGReport({});
  };

  // Download Excel Functionality

  const downloadMoneyTransactionPGReport = useCallback(() => {
    if (advanceFilterData) {
      if (advanceFilterData.method === 'All' && advanceFilterData.status === 'All') {
        const filterData = { ...advanceFilterData, method: '', status: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.method === 'All') {
        const filterData = { ...advanceFilterData, method: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.status === 'All') {
        const filterData = { ...advanceFilterData, status: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      }
    } else {
      const data = {
        excel: true,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      dispatch({
        type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadMoneyTransactionPGReport();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { method: '', status: '' };

  const allPaymentMethod = [
    { label: 'Net banking', value: 'netbanking' },
    { label: 'Card', value: 'card' },
    { label: 'Wallet', value: 'wallet' },
    { label: 'UPI', value: 'upi' },
  ];

  const allPaymentStatus = [
    { label: 'Paid', value: 'Paid' },
    { label: 'Failed', value: 'Failed' },
    { label: 'Pending', value: 'Pending' },
  ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.moneyTransactionPGReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                      {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.razorPayID')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.userEmail')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.mobileNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.orderID')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.paymentStatus')}</span>
                                {/* <span className="ico" onClick={() => handleSorting('details.status', sortByItem)}>
                                <BiSort size={15} />
                              </span> */}
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.errorMessage')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.paymentMethod')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.paymentType')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.transactionID')}</span>
                                <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.currency')}</span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.amount')}</span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addMoneyTransactionPGReport.transactionDateTime')}</span>
                                {/* <span className="ico" onClick={() => handleSorting('transaction_status', sortByItem)}>
                                <BiSort size={15} />
                              </span> */}
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={12} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(moneyTransactionPGReportList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('addMoneyTransactionPGReport.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(
                              _.filter(moneyTransactionPGReportList, (item) => item !== null),
                              (item, key) => {
                                // const amount = isNaN(_.get(item, 'details.amount', '') / 100)
                                //   ? '-'
                                //   : roundOfDigit(_.get(item, 'details.amount', '') / 100, 2);

                                let paymentType;
                                if (item?.payment?.details) {
                                  if (item?.payment?.details?.method === 'netbanking') {
                                    paymentType = item?.payment?.details?.bank;
                                  } else if (item?.payment?.details?.method === 'wallet') {
                                    paymentType = item?.payment?.details?.wallet;
                                  } else if (item?.payment?.details?.method === 'upi') {
                                    paymentType = item?.payment?.details?.vpa;
                                  } else if (item?.payment?.details?.method === 'card') {
                                    if (item?.payment?.details?.card) {
                                      paymentType = item?.payment?.details?.card?.network;
                                    } else {
                                      paymentType = '-';
                                    }
                                  } else if (item?.payment_log[0]?.details) {
                                    if (item?.payment_log[0]?.details?.method === 'netbanking') {
                                      paymentType = item?.payment_log[0]?.details?.bank;
                                    } else if (item?.payment_log[0]?.details?.method === 'wallet') {
                                      paymentType = item?.payment_log[0]?.details?.wallet;
                                    } else if (item?.payment_log[0]?.details?.method === 'upi') {
                                      paymentType = item?.payment_log[0]?.details?.vpa;
                                    } else if (item?.payment_log[0]?.details?.method === 'card') {
                                      if (item?.payment_log[0]?.details?.card) {
                                        paymentType = item?.payment_log[0]?.details?.card?.network;
                                      } else {
                                        paymentType = '-';
                                      }
                                    }
                                  }
                                } else {
                                  paymentType = '-';
                                }

                                let transactionId;
                                if (item?.payment?.transaction_id) {
                                  transactionId = item?.payment?.transaction_id;
                                } else if (item?.payment_log[0]?.transaction_id) {
                                  transactionId = item?.payment_log[0]?.transaction_id;
                                } else {
                                  transactionId = '-';
                                }

                                let orderId;
                                if (item?.payment?.details?.order_id) {
                                  orderId = item?.payment?.details?.order_id;
                                } else if (item?.payment_log[0]?.details?.order_id) {
                                  orderId = item?.payment_log[0]?.details?.order_id;
                                } else {
                                  orderId = '-';
                                }

                                let method;
                                if (item?.payment?.details?.method) {
                                  method = item?.payment?.details?.method;
                                } else if (item?.payment_log[0]?.details?.method) {
                                  method = item?.payment_log[0]?.details?.method;
                                } else {
                                  method = '-';
                                }

                                let currency;
                                if (item?.payment?.details?.currency) {
                                  currency = item?.payment?.details?.currency;
                                } else if (item?.payment_log[0]?.details?.currency) {
                                  currency = item?.payment_log[0]?.details?.currency;
                                } else {
                                  currency = '-';
                                }

                                let email;
                                if (item?.payment?.details?.email) {
                                  email = item?.payment?.details?.email;
                                } else if (item?.payment_log[0]?.details?.email) {
                                  email = item?.payment_log[0]?.details?.email;
                                } else {
                                  email = '-';
                                }

                                let contact;
                                if (item?.payment?.details?.contact) {
                                  contact = item?.payment?.details?.contact;
                                } else if (item?.payment_log[0]?.details?.contact) {
                                  contact = item?.payment_log[0]?.details?.contact;
                                } else {
                                  contact = '-';
                                }

                                let amount;
                                if (item?.payment?.details?.amount) {
                                  amount = roundOfDigit(item?.payment?.details?.amount / 100, 2);
                                } else if (item?.payment_log[0]?.details?.amount) {
                                  amount = roundOfDigit(item?.payment_log[0]?.details?.amount / 100, 2);
                                } else {
                                  amount = '-';
                                }

                                const serial_num = limit * (page - 1) + key;
                                return (
                                  <>
                                    <tr key={`money-transactionPG-report-${serial_num}`}>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : transactionId}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : email}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : contact}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : orderId}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'status', '')}</td>
                                      <td>
                                        {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'payment_log[0].details.error_code', '-')}
                                      </td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : method}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : paymentType}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'id', '-')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : currency}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : amount}</td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : item.createdAt ? (
                                          moment(item.createdAt).format('DD/MM/YYYY H:mm:ss')
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(moneyTransactionPGReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.method && delete values['method'];
              !values.status && delete values['status'];
              if (values.method === 'All' && values.status === 'All') {
                const moneyTransactionPGReportData = { ...values, method: '', status: '' };
                moneyTransactionPGReport(moneyTransactionPGReportData);
              } else if (values.method === 'All') {
                const moneyTransactionPGReportData = { ...values, method: '' };
                moneyTransactionPGReport(moneyTransactionPGReportData);
              } else if (values.status === 'All') {
                const moneyTransactionPGReportData = { ...values, status: '' };
                moneyTransactionPGReport(moneyTransactionPGReportData);
              } else {
                moneyTransactionPGReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.paymentMethod')}
                    options={
                      !changePaymentMethod
                        ? !_.isEmpty(allPaymentMethod) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allPaymentMethod, (item) => {
                              return { label: item.label, value: item.value };
                            }),
                          ]
                        : !_.isEmpty(allPaymentMethod) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder={t('placeHolder.selectPaymentMethod')}
                    name="method"
                    isMulti
                    value={values.method}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangePaymentMethod(true);
                        setFieldValue(`method`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`method`, '');
                        setChangePaymentMethod(false);
                      } else {
                        setChangePaymentMethod(false);
                        setFieldValue('method', val);
                      }
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.paymentStatus')}
                      options={
                        !changePaymentStatus
                          ? !_.isEmpty(allPaymentStatus) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allPaymentStatus, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allPaymentStatus) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectPaymentStatus')}
                      name="status"
                      isMulti
                      value={values.status}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangePaymentStatus(true);
                          setFieldValue(`status`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`status`, '');
                          setChangePaymentStatus(false);
                        } else {
                          setChangePaymentStatus(false);
                          setFieldValue('status', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      moneyTransactionPGReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default MoneyTransactionPGReport;
