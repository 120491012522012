import { FETCH_COUPONS, DELETE_COUPON, UPDATE_COUPON, CREATE_COUPON, GET_COUPON, BOOKING_COUPON } from 'actions/coupon';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  coupons: [],
  couponDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
  bookingCoupon: [],
};

const couponReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_COUPON.REQUEST:
    case DELETE_COUPON.REQUEST:
    case FETCH_COUPONS.REQUEST:
    case CREATE_COUPON.REQUEST:
    case GET_COUPON.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_COUPONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        coupons: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_COUPON.SUCCESS: {
      return {
        ...state,
        couponDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_COUPON.SUCCESS: {
      toast.success('Coupon added successfully');
      return {
        ...state,
        coupons: _.concat(state.coupons, payload),
        isLoading: false,
      };
    }

    case UPDATE_COUPON.SUCCESS: {
      toast.success('Coupon updated successfully');
      return {
        ...state,
        isLoading: false,
        coupons: state.coupons.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_COUPON.SUCCESS: {
      toast.success('Coupon deleted successfully');
      return {
        ...state,
        coupons: state.coupons.filter((data) => data.id !== payload),
      };
    }
    case BOOKING_COUPON.REQUEST:
    case BOOKING_COUPON.SUCCESS: {
      return {
        ...state,
        bookingCoupon: payload.results,
      };
    }

    case DELETE_COUPON.FAIL:
    case UPDATE_COUPON.FAIL:
    case FETCH_COUPONS.FAIL:
    case CREATE_COUPON.FAIL:
    case GET_COUPON.FAIL:
    case BOOKING_COUPON.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};

export default couponReducer;
