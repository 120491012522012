import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
// import SearchBox from 'components/general/SearchBox';
import { FAULTY_BOOKING_REPORT, DOWNLOAD_FAULTY_BOOKING_REPORT } from 'actions/dataReport';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
// import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { roundOfDigit } from 'components/common/utils';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';

const FaultyBookingReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  //   const [searchText, setSearchText] = useState('');
  //   const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const faultyBookingReportList = useSelector((state) => state.faultyBookingReport.faultyBookingReport);
  const isLoader = useSelector((state) => state.faultyBookingReport.isLoading);
  const page = useSelector((state) => state.faultyBookingReport.page);
  const totalData = useSelector((state) => state.faultyBookingReport.total);
  const limit = useSelector((state) => state.faultyBookingReport.limit);
  const totalPages = useSelector((state) => state.faultyBookingReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  const faultyReasons = [
    { label: 'Invalid Time', value: 'Invalid Time' },
    { label: 'SOC Invalid', value: 'SOC Invalid' },
    { label: 'Abnormal Unit Consumed', value: 'Abnormal Unit Consumed' },
    { label: 'Negative Meter Value', value: 'Negative Meter Value' },
    { label: 'In Progress 6 Hour', value: 'In Progress 6 Hour' },
    { label: 'SoC Updated but Unit Consumed 0', value: 'SoC Updated but Unit Consumed 0' },
    { label: 'Time changed but unit consumed is 0', value: 'Time changed but unit consumed is 0' },
  ];

  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));


  const faultyBookingReport = useCallback(
    (value) => {
      const data = {
        ...value,
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
     from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
      };
      dispatch({
        type: FAULTY_BOOKING_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            onCloseAdvanceFilterPopup();
          }
        },
      });
    },
    [startDate, endDate]
  );

  const downloadFaultyBookingReport = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      const data = {
        ...advanceFilterData,
        excel: true,
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
     from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
      };
      dispatch({
        type: DOWNLOAD_FAULTY_BOOKING_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Faulty Booking Report'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
     from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
      };
      dispatch({
        type: DOWNLOAD_FAULTY_BOOKING_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Faulty Booking Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData, userProfileStations]);

  const downloadFile = () => {
    downloadFaultyBookingReport();
  };

  useEffect(() => {
    faultyBookingReport();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const tooltip = document.getElementsByClassName('more-button-tooltip')[0];
      if (tooltip && !tooltip.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleTooltip = (content) => {
    setShowTooltip(!showTooltip);
    if (content) {
      setTooltipContent(content);
    }
  };

  console.log(tooltipContent);
  const renderFaultyBookingReason = (infoTitle, remainingNumbers, tooltipId) => {
    return (
      <>
        {infoTitle}
        {remainingNumbers.length > 0 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={tooltipId} className="more-button-tooltip">
                {remainingNumbers.join(', ')}
              </Tooltip>
            }
            show={showTooltip && tooltipContent === tooltipId}
            onHide={() => toggleTooltip('')}
          >
            <span className="more-button" onClick={() => toggleTooltip(tooltipId)}>
              {t('customerProfile.more')}
            </span>
          </OverlayTrigger>
        )}
      </>
    );
  };

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
     from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      const filterDateData = {
        ...advanceFilterData,
     from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
      };
      faultyBookingReport(filterDateData);
    } else {
      const filterDateData = {
     from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
      };
      faultyBookingReport(filterDateData);
    }
  }, [startDate, endDate, advanceFilterData, sortByItem]);

  //   const searchHandler = (event) => {
  //     const value = event.target.value;
  //     setSearchText(value);
  //   };

  //   const handleSearch = () => {
  //     faultyBookingReport({});
  //   };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
       from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && faultyBookingReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        faultyBookingReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && faultyBookingReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        faultyBookingReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        const data = {
          ...advanceFilterData,
          page: page.selected + 1,
       from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        faultyBookingReport(data);
      } else if (startDate && endDate) {
        const data = {
       from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        faultyBookingReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        faultyBookingReport(data);
      }
    },
    [startDate, endDate, advanceFilterData, sortByItem]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  //   const search = (
  //     <SearchBox
  //       preIcon={<BsSearch />}
  //       value={searchText}
  //       onChange={searchHandler}
  //       onKeyPress={(e) => {
  //         if (e.key === 'Enter') {
  //           handleSearch();
  //         }
  //       }}
  //     />
  //   );
  //   const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { reasons: '' };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.faultyBookingReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      {/* <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col> */}
                      <Col xl={'auto'} md={'auto'}>
                        <div style={{ display: 'flex', marginTop: '0.7rem', fontWeight: 'bold' }}>{t('faultyBookingReport.title')}</div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={startDate}
                            initialValueOfEndDate={endDate}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">Search</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      {/* <Col>{t('faultyBookingReport.title')}</Col> */}
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.chargerId')}</span>
                                <span className="ico" onClick={() => handleSorting('charger_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.bookingId')}</span>
                                <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.transactionID')}</span>
                                <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.idTag')}</span>
                                <span className="ico" onClick={() => handleSorting('idTag', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.meterStart')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.meterStop')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.unitConsumed')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.startTime')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.stopTime')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.startSOC')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.stopSOC')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.vehicleMake')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.batteryCapacity')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.paymentStatus')}</span>
                                <span className="ico" onClick={() => handleSorting('payment_status', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.faultyBookingReasons')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(faultyBookingReportList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">No Faulty Booking Report Found</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(faultyBookingReportList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              const reasons = _.get(item, 'faulty_booking_reason', '-');
                              const time = moment().subtract(6, 'hour');

                              let reason;
                              if (moment(item.booking_start) < time && item.status === 'in_progress') {
                                reason = 'Booking In Progress From Six Hours';
                              } else {
                                reason = renderFaultyBookingReason(_.head(reasons), _.tail(reasons), `reason-tooltip-${key}`);
                              }

                              return (
                                <>
                                  <tr key={`faulty-booking-report-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'id', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstart', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstop', '-')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        roundOfDigit((_.get(item, 'meterstop', '') - _.get(item, 'meterstart', '')) / 1000, 2)
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : item.booking_start ? (
                                        moment(item.booking_start).format('DD/MM/YYYY H:mm:ss')
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : item.booking_stop ? (
                                        moment(item.booking_stop).format('DD/MM/YYYY H:mm:ss')
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StartSoC', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StopSoC', '-')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        `${_.get(item, 'vehicle.make', '')} (${_.get(item, 'vehicle.model', '')})`
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'vehicle.max_charging_capacity', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'payment_status', '-')}</td>
                                    <td>
                                      {(() => {
                                        if (isLoader) {
                                          return <Skeleton height={15} width={100} />;
                                        } else if (!_.isEmpty(item.faulty_booking_reason) || item.status === 'in_progress') {
                                          return reason;
                                        } else {
                                          return '-';
                                        }
                                      })()}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(faultyBookingReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.reasons && delete values['reasons'];
              faultyBookingReport(values);
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.faultyReasons')}
                      options={[
                        ..._.map(faultyReasons, (reason) => {
                          return { value: reason.value, label: reason.label };
                        }),
                      ]}
                      placeholder={t('placeHolder.faultyReason')}
                      name="reasons"
                      isMulti
                      value={values.reasons}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue('reasons', val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      faultyBookingReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default FaultyBookingReport;
